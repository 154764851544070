import React from 'react';
import {Box} from "@mui/material"

function CodeBox({children}: { children: string }) {
    return (
        <Box fontSize="smaller"
             sx={{
                 p: 2,
                 backgroundColor: "#f2f2f2",
                 fontFamily: "'Courier', serif",
                 whiteSpace: "pre-wrap",
                 overflowY: "scroll",
             }}>
            {children}
        </Box>
    );
}

export default CodeBox;