import React, {useEffect, useState} from 'react';
import {useProjectData} from "../../hooks/useProjectData"
import ProjectPageHeader from "../layouts/ProjectPageHeader"
import {
    Box,
    CircularProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material"
import {TemplateType} from "../../api/enum/TemplateType"
import {Link as RouterLink, useNavigate} from "react-router-dom"
import ApiError from "../../api/ApiError"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import {TemplateRequests} from "../../api/requests/TemplateRequests"
import {TimeUtil} from "../../library/TimeUtil"
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline"
import ViewStreamOutlinedIcon from "@mui/icons-material/ViewStreamOutlined"
import TemplateActionsMenu from "./TemplateActionsMenu"
import TemplateRenderer from "./TemplateRenderer"
import ErrorMessage from "../error/ErrorMessage"

function TemplatesPage() {
    const {id, project} = useProjectData()

    const [templates, setTemplates] = useState<AnyTemplateJson[]>()
    const [templatesLoading, setTemplatesLoading] = useState(false)
    const [loadingError, setLoadingError] = useState<ApiError>()

    const navigate = useNavigate()

    useEffect(() => {
        if (templatesLoading || templates) return

        load()
    }, [project])

    const load = () => {
        setTemplatesLoading(true)
        TemplateRequests.list(id)
            .then(response => {
                setTemplates(response.content)
                setLoadingError(undefined)
            })
            .catch(setLoadingError)
            .finally(() => setTemplatesLoading(false))
    }

    if (!project) {
        return <CircularProgress/>
    }

    return (
        <div>
            <ProjectPageHeader
                showFab
                onFabClick={() => navigate(`/projects/${id}/templates/new`)}
                pageTitle="Templates"
            />

            {loadingError && <ErrorMessage error={loadingError}/>}
            {templatesLoading && <CircularProgress/>}

            {templates &&
                <Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="templates table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Preview</TableCell>
                                    <TableCell>Tokens</TableCell>
                                    <TableCell>Models</TableCell>
                                    <TableCell>Updated</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {templates.map(template => {
                                        const tokenCount = template.tokenCounts.find(tokenCount => tokenCount.algorithm === project.defaultTokenizer)?.count

                                        return (
                                            <TableRow
                                                key={template.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>
                                                    <Link component={RouterLink}
                                                          to={`/projects/${id}/templates/${template.id}`}>
                                                        {template.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {template.type === TemplateType.CHAT &&
                                                        <Tooltip title="Chat">
                                                            <ChatBubbleOutlineIcon color="secondary" fontSize="small"/>
                                                        </Tooltip>
                                                    }
                                                    {template.type === TemplateType.STANDARD &&
                                                        <Tooltip title="Standard">
                                                        <ViewStreamOutlinedIcon color="secondary" fontSize="small"/>
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <Box sx={{maxHeight: 320, overflowY: "scroll"}}>
                                                        <TemplateRenderer templateParts={template} staticSystemPromptTreatment="show"/>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{tokenCount}</TableCell>
                                                <TableCell>{template.stats && template.stats.numberOfTemplatedModels > 0 && template.stats.numberOfTemplatedModels}</TableCell>
                                                <TableCell>{TimeUtil.ago(template.updated)}</TableCell>
                                                <TableCell>
                                                    <TemplateActionsMenu project={project}
                                                                         template={template}
                                                                         didRename={(renamed) => {
                                                                             const index = templates.findIndex(t => t.id === renamed.id)
                                                                             if (index === -1) {
                                                                                 load()
                                                                             } else {
                                                                                 const updated = [...templates]
                                                                                 updated[index].name = renamed.name
                                                                                 setTemplates(updated)
                                                                             }
                                                                         }}
                                                                         didCopy={(copy) => setTemplates([copy, ...templates])}
                                                                         didDelete={() => {
                                                                             const index = templates?.findIndex(t => t.id === template.id)
                                                                             if (index !== -1) {
                                                                                 const updated = [...templates]
                                                                                 updated.splice(index, 1)
                                                                                 setTemplates(updated)
                                                                             }
                                                                         }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    },
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            }
        </div>
    );
}

export default TemplatesPage;