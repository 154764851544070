import React, {useEffect} from 'react';
import {useIntegrations} from "../../hooks/useIntegrations"
import {useModelSpecs} from "../../hooks/useModelSpecs"
import {Button, Modal, Stack, Typography} from "@mui/material"
import modalStyles from "../modal/Modal.module.scss"
import ModelFinder from "../models/ModelFinder"
import CompletionOptionsForm from "./CompletionOptionsForm"
import {AnyModelSpecJson} from "../../api/json/ModelSpecJson"
import {CompletionOptionsJson} from "../../api/json/CompletionOptionsJson"

interface CompletionOptionsFormModalProps {
    open: boolean
    setOpen: (newValue: boolean) => void
    model?: AnyModelSpecJson
    options?: CompletionOptionsJson
    updateModel: (newModel: AnyModelSpecJson | undefined) => void
    updateCompletionOptions: (newCompletionOptions: CompletionOptionsJson) => void
}

function CompletionOptionsFormModal({ open, setOpen, model, options, updateModel, updateCompletionOptions}: CompletionOptionsFormModalProps) {
    const {openAi} = useIntegrations()
    const {models} = useModelSpecs()

    useEffect(() => {
        if (!openAi || !models) return

        const defaultModel = openAi ? models?.find(it => it.name === "gpt-4-0125-preview") : undefined
        if (!model && defaultModel) {
            updateModel(defaultModel)
        }

    }, [openAi, models])

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Stack spacing={2} className={modalStyles.default}>
                <Typography variant="h2">Completion Options</Typography>
                <ModelFinder instructModelsOnly value={model} onChange={updateModel}/>
                {model &&
                    <CompletionOptionsForm platform={model.platform} value={options} onChange={updateCompletionOptions}/>
                }
                <Button variant="contained" sx={{alignSelf: "flex-end"}} onClick={() => setOpen(false)}>Done</Button>
            </Stack>
        </Modal>
    );
}

export default CompletionOptionsFormModal;