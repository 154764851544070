import React, {useRef, useState} from 'react';
import {Box, Button, Fab, SxProps, TextField} from "@mui/material";
import {AnyFieldJson} from "../../api/json/FieldJson";
import AddIcon from '@mui/icons-material/Add';
import FieldModal from "../fields/FieldModal"
import {useProjectData} from "../../hooks/useProjectData"

interface TemplateTextFieldProps {
    label?: string
    value: string
    unreferencedFields: AnyFieldJson[]
    setValue: (newValue: string) => void
    sx?: SxProps
    showInsertButtons: boolean
    didFocus: () => void
}

export default function FieldReferenceTextField({label, value, unreferencedFields, setValue, didFocus, showInsertButtons, sx}: TemplateTextFieldProps) {
    const {project, reloadProject} = useProjectData()

    const [showNewFieldModal, setShowNewFieldModal] = useState(false)
    const textField = useRef<HTMLInputElement>(null)

    const insertField = (event: React.MouseEvent, field: AnyFieldJson) => {
        event.preventDefault()
        if (!textField.current) return

        let cursorPosition = textField.current.selectionStart
        let insertText = `{{${field.reference}}}`

        if (cursorPosition === null) return
        let textBeforeCursorPosition = textField.current.value.substring(0, cursorPosition)
        let textAfterCursorPosition = textField.current.value.substring(cursorPosition, textField.current.value.length)
        textField.current.value = textBeforeCursorPosition + insertText + textAfterCursorPosition

        let endCursorPosition = cursorPosition + insertText.length

        textField.current.focus()
        textField.current.setSelectionRange(endCursorPosition, endCursorPosition)

        setValue(textField.current.value)
    }

    return (
        <Box sx={sx}
             onFocus={() => didFocus()}
        >
            <TextField inputRef={textField}
                       multiline
                       fullWidth
                       label={label}
                       value={value}
                       onChange={(event) => setValue(event.target.value)}
            />
            {showInsertButtons &&
                <Box sx={{mt:1, overflow:"hidden"}} onMouseDown={e => e.preventDefault()}>
                    {unreferencedFields.map(field => (
                        <Button key={field.id}
                                variant="contained"
                                size="small"
                                onClick={(event) => insertField(event, field)}
                                color="secondary"
                                startIcon={<AddIcon/>}
                                sx={{float: "left", mr: 1, mb: 1}}
                        >
                            {field.name}
                        </Button>
                    ))}
                    <Button variant="outlined"
                            size="small"
                            startIcon={<AddIcon/>}
                            onClick={() => setShowNewFieldModal(true)}
                    >
                        New field
                    </Button>
                </Box>
            }
            <Box sx={{clear:"both"}}></Box>

            {project &&
                <FieldModal project={project} open={showNewFieldModal} close={() => {
                    setShowNewFieldModal(false)
                    reloadProject()
                }} />
            }
        </Box>
    );
}