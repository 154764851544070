import React from 'react';
import {
    Box,
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {FieldType, resolveNameForFieldType} from "../../api/enum/FieldType";
import NotesIcon from "@mui/icons-material/Notes";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import NumbersIcon from "@mui/icons-material/Numbers";
import LinkIcon from "@mui/icons-material/Link";
import EditIcon from "@mui/icons-material/Edit";
import {AnyFieldJson} from "../../api/json/FieldJson";
import {ProjectJson} from "../../api/json/ProjectJson"
import FieldIssueIcon from "./FieldIssueIcon"
import InlineCode from "../common/InlineCode"

interface FieldsTableProps {
    project: ProjectJson
    fields: AnyFieldJson[]
    handleEdit: (field: AnyFieldJson) => void
}

function FieldsTable({ project, fields, handleEdit }: FieldsTableProps) {
    const sortedFields = fields.sort((a,b) => a.order - b.order)
    return (
        <Box>
            <TableContainer component={Paper}>
                <Table aria-label="classifications table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Reference</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedFields.map(field => (
                            <TableRow
                                key={field.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                                        <FieldIssueIcon project={project} field={field}/>
                                        <Typography variant="body1">{field.name}</Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <InlineCode>{field.reference}</InlineCode>
                                </TableCell>
                                <TableCell>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {field.type === FieldType.TEXT && <NotesIcon fontSize="small"/>}
                                        {field.type === FieldType.PREDEFINED_OPTIONS &&
                                            <FormatListBulletedIcon fontSize="small"/>}
                                        {field.type === FieldType.NUMBER && <NumbersIcon fontSize="small"/>}
                                        {field.type === FieldType.URL && <LinkIcon fontSize="small"/>}
                                        <Typography
                                            variant="body2">{resolveNameForFieldType(field.type)}</Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell align="right">
                                    <Button
                                        variant="contained"
                                        color="info"
                                        startIcon={<EditIcon/>}
                                        onClick={(event) => handleEdit(field)}
                                    >
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default FieldsTable;