import React from 'react';
import {Stack, SxProps} from "@mui/material"
import {AnyFieldJson} from "../../api/json/FieldJson"
import FieldValueInputWithHelperText from "../examples/FieldValueInputWithHelperText"
import {AnyTemplateJson} from "../../api/json/TemplateJson"
import TemplateUtil from "../../library/TemplateUtil"

interface InferencePromptFormProps {
    fields: AnyFieldJson[]
    template: AnyTemplateJson
    fieldValues: Record<string, string>
    updateFieldValues: (fieldValues: Record<string, string>) => void
    sx?: SxProps
}

function InferencePromptForm({ fields, template, fieldValues, updateFieldValues, sx }: InferencePromptFormProps) {
    const promptReferences = TemplateUtil.findPromptReferences(template)
    const formFields = fields.filter(field => promptReferences.includes(field.reference))

    const handleFieldValueChange = (key: string, newValue: string) => {
        let newFieldValuesByReference = {...fieldValues}
        newFieldValuesByReference[key] = newValue
        updateFieldValues(newFieldValuesByReference)
    }

    return (
        <Stack spacing={2} sx={sx}>
            {formFields.map((field, _) => {
                return <FieldValueInputWithHelperText
                    key={field.id}
                    field={field}
                    value={fieldValues[field.reference] ?? ""}
                    onChange={newValue => handleFieldValueChange(field.reference, newValue)}
                />
            })}
        </Stack>
    );
}

export default InferencePromptForm;